<template>
  <div class="get_m_a_s">
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="queryData"
          class="ylsdyrmyy-form-inline"
          size="mini"
          ref="queryData"
        >
          <el-form-item label="测评量表" prop="id">
            <el-select v-model="queryData.mid" filterable>
              <el-option
                :label="item.measure_title"
                :value="item.id"
                v-for="(item, key) in scaleList"
                :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="科室部门" prop="department_id">
            <UnlimateSelect :item-data="departmentList" @input="queryData.did = $event[0]" />
          </el-form-item>

          <el-form-item label="时间范围" prop="date">
            <!-- <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
            ></el-date-picker> -->
               <el-date-picker
                  v-model="date[0]"
                  type="date"
                  placeholder="开始日期"
                  value-format="timestamp"
                >
                </el-date-picker>
                 <span style="padding-right:16px">至</span>
                <!-- 结束 -->
                <el-date-picker
                  v-model="date[1]"
                  type="date"
                  placeholder="结束日期"
                  value-format="timestamp"
                >
                </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="getTable">查询</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table :data="tableData" style="width: 100%">
        <!--------------------------------------- 二级表格------------------------------------------ -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table :data="scope.row.list" style="width: 100%">
              <el-table-column
                :label="scope.row.name + ' -【已有量表】'"
                prop="measure_title"
              ></el-table-column>
              <el-table-column label="男" prop="sex0" align="center">
              </el-table-column>
              <el-table-column label="女" prop="sex1" align="center">
              </el-table-column>
              <el-table-column label="量表总人数" prop="sort" align="center">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.sex1 + scope.row.sex0 }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <!-- -------------------------------------------------------------------------------------- -->
        <el-table-column
          label="科室部门名称"
          prop="name"
          align="center"
        ></el-table-column>
        <el-table-column label="男（报告总数）" prop="sum_sex0" align="center">
        </el-table-column>
        <el-table-column label="女（报告总数）" prop="sum_sex1" align="center">
        </el-table-column>
        <el-table-column label="当前科室报告总数" prop="sort" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.sum_sex1 + scope.row.sum_sex0 }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { loadArchivesInfo } from "@/api/evaluate.js";
import { loadDepartmentData } from "@/api/comm.js";
import { get_measure_user_statistics } from "@/api/user_info_statistical.js";
import axios from "axios";
export default {
  data() {
    return {
      scaleData: [],
      departmentList: [],
      formInline: {},
      scaleList: [],
      tableData: [
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
      ],
      date: [],
      queryData: {
        did: "",
        min: "",
        stime: "",
        etime: "",
      },
    };
  },
  created() {
    // 获取量表列表
    this.loadScaleData();
    // 获取所有部门 done
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
    this.getTable();
  },
  methods: {
    // 量表数据请求
    async loadScaleData() {
      var res = await axios.get(`${process.env.VUE_APP_PortUrl}/all_measure_classify`);
      if (res.data.code === 400200) {
        this.scaleData = res.data.data;
        res.data.data.forEach((v) => {
          v.status_measure.forEach((v) => {
            this.scaleList.push(v);
          });
        });
      } else {
        return;
      }
    },
    //
    getTable() {
      // console.log(this.date);
      if(this.date[0]||this.date[1]){
        if(!this.date[0]||!this.date[1]){
          this.$message({
            message: "请选择完整时间范围或者不选择时间范围!",
            type: "error",
          });
          return
        }
      }
      let stime = "";
      let etime = "";
      if (this.date) {
        stime = this.date[0] / 1000;
        etime = this.date[1] / 1000;
      }
      // 时间处理
      let data = {
        did: this.queryData.did || null,
        mid: this.queryData.mid || null,
        stime: stime || null,
        etime: etime || null,
      };
      get_measure_user_statistics(data).then((res) => {
        console.log(res);
        this.tableData = res.data;
      });
    },
    getchartData() {},
    resetForm(formName) {
      this.queryData = {
        did: "",
        mid: "",
      };
      this.date = [];
    },
  },
};
</script>
<style lang="less">
.get_m_a_s {
  width: 100%;
  height: calc(~"100% - 126px");
  padding-bottom: 10px;

  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 140px;
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }
      .el-form-item:nth-child(3) .el-input__inner {
        // width: 220px;
        padding-right: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 745px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  .el-table__footer-wrapper {
    .el-table__footer {
      .has-gutter {
        tr {
          td {
            background: rgb(252, 218, 28);
            color: #3c3c3c;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
